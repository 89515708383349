// Generated by Framer (8c7926c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const MotionDivWithFX = withFX(motion.div);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["H7BAY5GA_", "dZ_xhY7io", "tY2YIouK6", "vjPl5zsut"];

const serializationHash = "framer-4Vmwr"

const variantClassNames = {dZ_xhY7io: "framer-v-1k7g62x", H7BAY5GA_: "framer-v-1rmto0p", tY2YIouK6: "framer-v-11m1o4k", vjPl5zsut: "framer-v-12cj0pz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 0, skewX: 0, skewY: 0, x: 0, y: 0}

const transition1 = {damping: 42, delay: 0.2, mass: 6, stiffness: 701, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 0, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const transition2 = {delay: 0, duration: 2, ease: [0.44, 0, 0.56, 1], type: "tween"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "H7BAY5GA_", "Variant 2": "dZ_xhY7io", "Variant 3": "tY2YIouK6", "Variant 4": "vjPl5zsut"}

const getProps = ({background, height, icon, id, width, ...props}) => { return {...props, C7C1yjUQE: background ?? props.C7C1yjUQE ?? "rgb(234, 128, 255)", PnFuM7jEd: icon ?? props.PnFuM7jEd ?? "Broadcast", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "H7BAY5GA_"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: string;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PnFuM7jEd, C7C1yjUQE, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "H7BAY5GA_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear10lbz7a = activeVariantCallback(async (...args) => {
await delay(() => setVariant("dZ_xhY7io"), 2400)
})

const onAppear5ap7q0 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("H7BAY5GA_"), 2500)
})

const onAppear15pay8c = activeVariantCallback(async (...args) => {
await delay(() => setVariant("vjPl5zsut"), 2000)
})

const onAppear1o3akkz = activeVariantCallback(async (...args) => {
await delay(() => setVariant("tY2YIouK6"), 2000)
})

useOnVariantChange(baseVariant, {default: onAppear10lbz7a, dZ_xhY7io: onAppear5ap7q0, tY2YIouK6: onAppear15pay8c, vjPl5zsut: onAppear1o3akkz})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1rmto0p", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"H7BAY5GA_"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({dZ_xhY7io: {"data-framer-name": "Variant 2"}, tY2YIouK6: {"data-framer-name": "Variant 3"}, vjPl5zsut: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ulq2a1"} layoutDependency={layoutDependency} layoutId={"ptcsAWfPn"} style={{backgroundColor: C7C1yjUQE, borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.25)"}}><ComponentViewportProvider ><motion.div className={"framer-1bm8uoe-container"} layoutDependency={layoutDependency} layoutId={"qqUiqyRRa-container"} transformTemplate={transformTemplate1}><Phosphor color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"House"} iconSelection={PnFuM7jEd} id={"qqUiqyRRa"} layoutId={"qqUiqyRRa"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4Vmwr.framer-uxa13n, .framer-4Vmwr .framer-uxa13n { display: block; }", ".framer-4Vmwr.framer-1rmto0p { height: 50px; overflow: visible; position: relative; width: 50px; }", ".framer-4Vmwr .framer-ulq2a1 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-4Vmwr .framer-1bm8uoe-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 32px); left: 50%; position: absolute; top: 50%; width: 32px; }", ".framer-4Vmwr.framer-v-1k7g62x.framer-1rmto0p, .framer-4Vmwr.framer-v-11m1o4k.framer-1rmto0p, .framer-4Vmwr.framer-v-12cj0pz.framer-1rmto0p { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 50px); }", ".framer-4Vmwr.framer-v-1k7g62x .framer-ulq2a1 { bottom: unset; height: 50px; top: -4px; }", ".framer-4Vmwr.framer-v-12cj0pz .framer-ulq2a1 { bottom: -6px; height: 50px; top: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 50
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dZ_xhY7io":{"layout":["fixed","fixed"]},"tY2YIouK6":{"layout":["fixed","fixed"]},"vjPl5zsut":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PnFuM7jEd":"icon","C7C1yjUQE":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerfc964vg2D: React.ComponentType<Props> = withCSS(Component, css, "framer-4Vmwr") as typeof Component;
export default Framerfc964vg2D;

Framerfc964vg2D.displayName = "Lotus Feature";

Framerfc964vg2D.defaultProps = {height: 50, width: 50};

addPropertyControls(Framerfc964vg2D, {variant: {options: ["H7BAY5GA_", "dZ_xhY7io", "tY2YIouK6", "vjPl5zsut"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}, PnFuM7jEd: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Broadcast", description: undefined, hidden: undefined, title: "Icon"}, C7C1yjUQE: {defaultValue: "rgb(234, 128, 255)", title: "Background", type: ControlType.Color}} as any)

addFonts(Framerfc964vg2D, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})